import React, { useState } from "react";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropTypes from "prop-types";
import Modal from "antd/lib/modal/Modal";
import { Carousel } from "react-responsive-carousel";
import { Image } from "antd";
import { url } from "../api";
import { ZoomInOutlined } from "@ant-design/icons";

export default function Timeline({ timeline }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [month, setMonth] = useState({});
  return (
    <>
      <Modal
        onCancel={() => setModalVisible(false)}
        footer={null}
        title={month.mes}
        visible={modalVisible}
        style={{ top: "20px" }}
        destroyOnClose
      >
        <Carousel
          showStatus={false}
          centerMode
          centerSlidePercentage="100"
          infiniteLoop
          dynamicHeight
        >
          {month.fotos?.map((f) => (
            <Image
              preview={{
                mask: (
                  <div className="flex">
                    <ZoomInOutlined className="mr-1 mt-1" />
                    <div>Ampliar</div>
                  </div>
                ),
              }}
              key={f.id}
              style={{ width: f.height / f.width > 1 && "55%" }}
              src={`${url}${f.url}`}
            />
          ))}
        </Carousel>
      </Modal>
      <div className="grid lg:grid-cols-4 grid-cols-1 gap-3 mt-10">
        {timeline.map(
          (i) =>
            i.fotos[0] && (
              <div
                style={{ height: "12rem", overflow: "hidden" }}
                key={i.id}
                className="self-center link"
              >
                <center>
                  <img
                    src={`${url}${i.fotos[0].url}`}
                    style={{ minHeight: "12rem", margin:'1rem' }}
                    onClick={() => {
                      setMonth(i);
                      setModalVisible(true);
                    }}
                  />
                  <p>{i.mes}</p>
                </center>
              </div>
            )
        )}
      </div>
    </>
  );
}

Timeline.propTypes = {
  timeline: PropTypes.array,
};
