import React from "react";
import PropTypes from "prop-types";

export default function Progress({ percentage, description }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
      <h4 className="bivverBlack font-semibold">{description}</h4>
      <div className="flex">
        <div
          className="w-full rounded-lg"
          style={{ height: "1rem", backgroundColor: "#E0E0E0" }}
        >
          <div
            className=" border-solid border-2 rounded-lg"
            style={{
              height: "1rem",
              backgroundColor: "#D27A34",
              width: `${percentage}%`,
            }}
          />
        </div>
        <div className="ml-3 w-1/6 -mt-1">{percentage}%</div>
      </div>
    </div>
  );
}
Progress.propTypes = {
  percentage: PropTypes.any,
  description: PropTypes.any,
};
